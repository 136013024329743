export function checkArray<T>(array: Array<T>): number {
    if (array && !Array.isArray(array)) {
        throw new Error("Expecting an array enumerable");
    }

    return (array ?? []).length;
}

export function firstOrDefault<T>(array: Array<T>): T {
    return checkArray(array) ?
        (array ?? [])[0] ?? null :
        null;
}

export function first<T>(array: Array<T>) {
    if (checkArray(array) > 0) {
        return array[0];
    }

    throw new Error("Enumerable contains no elements")
}

export function singleOrDefault<T>(array: Array<T>): T {
    if (checkArray(array) <= 1) {
        return firstOrDefault(array);
    }

    throw new Error("Enumerable has more than 1 element");
}

export function single<T>(array: Array<T>): T {
    if (checkArray(array) === 1) {
        return first(array);
    }

    throw new Error("Enumerable has 0 element or more than 1 element");
}

export function lastOrDefault<T>(array: Array<T>): T {
    if (array == null) {
        return null
    } else if (Array.isArray(array)) {
        return firstOrDefault([...array].reverse());
    }

    throw new Error("Expecting an array enumerable");
}