declare global {
    interface Date {
        addDays(days: number): Date;
        removeTime(): Date;
    }
}

Date.prototype.addDays = function(days: number): Date {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);

    return date;
}

Date.prototype.removeTime = function(): Date {
    const date = new Date(this.valueOf());
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
    );
}

export class DateHelpers{}; // Declares this file as a module
